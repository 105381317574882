import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.css';

import OrgChart from './OrgChart';

function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Route exact path="/">
          <div className="home">
            Cloudflare General Assignment &bull; See <a href="/orgchart">/orgchart</a>
          </div>
        </Route>

        <Route exact path="/orgchart">
          <OrgChart />
        </Route>
      </BrowserRouter>
    </div>
  );
}

export default App;
