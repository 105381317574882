import React from "react";
import OrgChart from "@unicef/react-org-chart";

import avatarPersonnel from './assets/avatar-personnel.svg'

export default class OrgChartPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      config: undefined
    }
  }

  componentDidMount() {
    fetch("/organization-chart")
      .then(res => res.json())
      .then(data => this.setState({ data }));
  }

  render() {
    if (this.state.data === null) {
      return <div className="home">Loading...</div>
    }

    const departments = [];
    let totalReports = 0;
    for (const department of this.state.data.organization.departments) {
      const employees = [];

      for (const person of department.employees) {
        if (person.name === department.managerName) continue;

        const id = totalReports++ + 1;
        employees.push({
          id,
          person: {
            id,
            avatar: "",
            department: department.name,
            name: person.name,
            title: `Employee, ${department.name}`,
            totalReports: 0
          },
          hasChild: false,
          hasParent: true,
          isHighlight: true,
          children: []
        });
      }

      const id = totalReports++ + 1;
      departments.push({
        id,
        person: {
          id,
          avatar: "",
          department: department.name,
          name: department.managerName,
          title: `Manager, ${department.name}`,
          totalReports: department.employees.length - 1
        },
        hasChild: true,
        hasParent: true,
        isHighlight: true,
        children: employees
      });
    }

    const tree = {
      id: 1000,
      person: {
        id: 1000,
        avatar: "",
        department: "",
        name: "CEO",
        title: "CEO",
        totalReports
      },
      hasChild: true,
      hasParent: false,
      isHighlight: true,
      children: departments
    }

    return (
      <>
        <OrgChart tree={tree}
          onConfigChange={config => this.setState({ config })}
          loadConfig={_d => this.state.config}
          loadImage={_d => {
            return Promise.resolve(avatarPersonnel)
          }} />

        <div className="floating">
          Click nodes to expand/collapse. Each of the four managers has a number of hidden employees!
        </div>
      </>

    )
  }
}